import React, { useState } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import DragItem from "./sidePanel/DragItem.js";
import { toHaveDisplayValue } from "@testing-library/jest-dom/dist/matchers.js";

import { SketchPicker } from 'react-color';
import { GithubPicker } from 'react-color';



const ResponsiveReactGridLayout = WidthProvider(Responsive);
// const originalLayouts = getFromLS("layouts") || {};
// const originalLayouts = getFromLS("layouts") || {};

// const ItemColors = new Map();
const ItemColors = {
  'Surgery': "#DDD9C4",
  'Medicine': "#E6B8B7",
  'Psychiatry': "#8DB4E2",
  'Pediatrics': "#FCD5B4",
  'OBGYN': "#D9D9D9",
  'Fam Med': "#B8CCE4",
  'Neurology': "#CCC0DA",
  'EM': "#93CDDC",
  'Electives': "#D8E4BC",
  'Research': "#EBF1DE",
  'Vaca': "#D1FAE5",
}

/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */

export default class MyGrid extends React.PureComponent {

  // state = { layout: [] };

  // onLayoutChange = layout => {
  //   this.setState({ layout: layout });
  // };

  static defaultProps = {
    className: "layout",
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    // cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    // cols: { lg: 53, md: 53, sm: 53, xs: 53, xxs: 53 },
    cols: { lg: 52, md: 52, sm: 52, xs: 52, xxs: 52 },
    // cols: 56,
    // cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 25,// 50, // 200, //100, //200,
    // isBounded: false,
    // containerPadding: [10,10],
    margin: [0, 1],
    // style: {}
    // droppingItem: { i: "test", h: 1, w: 1 },
    isDroppable: true,
    isDraggable: true,
    // verticalCompact: false,
    // compactType: null,
    preventCollision: true,
  };

  // randomColor() {
  //   return "#" + ((1 << 24) * Math.random() | 0).toString(16);
  // }

  constructor(props) {

    let { year, startDate, template } = props;
    const originalLayout = JSON.parse(localStorage.getItem(year)) || {};

    super(props);

    let yearByWeekArr = this.generateYearTimeline(year); // Array.from(Array(53), (_, i) => i + 1);
    yearByWeekArr.pop();  //FIXME: Super hacky work-around to remove last week
    yearByWeekArr.pop();  //FIXME: Super hacky work-around to remove last week
    let yearByMonthArr = this.generateYearTimeline(year);
    // let placeholders = Array.from(Array(10), (_, i) => i + 1);

    let templatePlaceholders = [];

    let clerkships = ["Surgery", "Medicine", "Psychiatry", "Pediatrics", "OBGYN", "Fam Med", "Neurology", "EM"];
    let clerkshipWeeks = [8, 8, 4, 6, 6, 3, 3, 4];
    let numClerkPerYear = [6, 6, 12, 8, 8, 16, 16, 12]

    for (let i = 0; i <= clerkships.length; i++) {
      let currLength = clerkshipWeeks[i];
      let currLabel = clerkships[i];
      let numClerkItems = numClerkPerYear[i];
      let xpos = 0;
      for (let j = 0; j < numClerkItems; j++) {
        if (j >= numClerkItems / 2) {
          xpos = j * currLength + 2;
        } else {
          xpos = j * currLength;
        }
        let currItem = {
          i: currLabel,
          x: xpos,
          y: i + 2,
          w: currLength,
          h: 1, //1/2,// 1/8,
          static: true,
          isResizable: false,
          // add: i === (list.length),
          bg: ItemColors[currLabel], // "#" + ((1 << 24) * Math.random() | 0).toString(16), //this.randomColor(),
          key: (Math.random() * 100000).toFixed(0) + i + j,
        }
        templatePlaceholders.push(currItem);
      }
    }
    templatePlaceholders.push({ "i": "Vacation/Research", "x": 24, "y": 2, "w": 1, "h": 9, "minW": 1, "minH": 0.49, "bg": "#D1FAE5", "key": (Math.random() * 100000).toFixed(0) })
    templatePlaceholders.push({ "i": "Vacation/Research", "x": 25, "y": 2, "w": 1, "h": 9, "minW": 1, "minH": 0.49, "bg": "#D1FAE5", "key": (Math.random() * 100000).toFixed(0) })
    templatePlaceholders.push({ "i": "Vacation/Research", "x": 50, "y": 2, "w": 1, "h": 8, "minW": 1, "minH": 0.49, "bg": "#D1FAE5", "key": (Math.random() * 100000).toFixed(0) })
    templatePlaceholders.push({ "i": "Vacation/Research", "x": 51, "y": 2, "w": 1, "h": 8, "minW": 1, "minH": 0.49, "bg": "#D1FAE5", "key": (Math.random() * 100000).toFixed(0) })
    templatePlaceholders.push({ "i": "Electives", "x": 0, "y": 9, "w": 24, "h": 1, "minW": 1, "minH": 0.49, "bg": "#D8E4BC", "key": (Math.random() * 100000).toFixed(0) })
    templatePlaceholders.push({ "i": "Electives", "x": 26, "y": 9, "w": 26, "h": 1, "minW": 1, "minH": 0.49, "bg": "#D8E4BC", "key": (Math.random() * 100000).toFixed(0) })
    templatePlaceholders.push({ "i": "Vacation/Research", "x": 0, "y": 10, "w": 52, "h": 1, "minW": 1, "minH": 0.49, "bg": "#D1FAE5", "key": (Math.random() * 100000).toFixed(0) })


    let nonTemplatePlaceholders = [
      {
        "i": "Surgery",
        "x": 0,
        "y": 2,
        "w": 8,
        "h": 1,
        "minW": 1,
        "minH": 0.49,
        "bg": "#DDD9C4",
        "key": (Math.random() * 100000).toFixed(0),
      },
      {
        "i": "Medicine",
        "x": 8,
        "y": 2,
        "w": 8,
        "h": 1,
        "minW": 1,
        "minH": 0.49,
        "bg": "#E6B8B7",
        "key": (Math.random() * 100000).toFixed(0),
      },
      {
        "i": "Psychiatry",
        "x": 16,
        "y": 2,
        "w": 4,
        "h": 1,
        "minW": 1,
        "minH": 0.49,
        "bg": "#8DB4E2",
        "key": (Math.random() * 100000).toFixed(0),
      },
      {
        "i": "Pediatrics",
        "x": 20,
        "y": 2,
        "w": 6,
        "h": 1,
        "minW": 1,
        "minH": 0.49,
        "bg": "#FCD5B4",
        "key": (Math.random() * 100000).toFixed(0),
      },
      {
        "i": "OBGYN",
        "x": 26,
        "y": 2,
        "w": 6,
        "h": 1,
        "minW": 1,
        "minH": 0.49,
        "bg": "#D9D9D9",
        "key": (Math.random() * 100000).toFixed(0),
      },
      {
        "i": "Fam Med",
        "x": 32,
        "y": 2,
        "w": 3,
        "h": 1,
        "minW": 1,
        "minH": 0.49,
        "bg": "#B8CCE4",
        "key": (Math.random() * 100000).toFixed(0),
      },
      {
        "i": "Neurology",
        "x": 35,
        "y": 2,
        "w": 3,
        "h": 1,
        "minW": 1,
        "minH": 0.49,
        "bg": "#CCC0DA",
        "key": (Math.random() * 100000).toFixed(0),
      },
      {
        "i": "EM",
        "x": 38,
        "y": 2,
        "w": 4,
        "h": 1,
        "minW": 1,
        "minH": 0.49,
        "bg": "#93CDDC",
        "key": (Math.random() * 100000).toFixed(0),
      },
      {
        "i": "Research",
        "x": 42,
        "y": 2,
        "w": 4,
        "h": 1,
        "minW": 1,
        "minH": 0.49,
        "bg": "#EBF1DE",
        "key": (Math.random() * 100000).toFixed(0),
      },
      {
        "i": "Electives",
        "x": 46,
        "y": 2,
        "w": 3,
        "h": 1,
        "minW": 1,
        "minH": 0.49,
        "bg": "#D8E4BC",
        "key": (Math.random() * 100000).toFixed(0),
      },
      {
        "i": "Vacation",
        "x": 49,
        "y": 2,
        "w": 3,
        "h": 1,
        "minW": 1,
        "minH": 0.49,
        "bg": "#D1FAE5",
        "key": (Math.random() * 100000).toFixed(0),
      }
    ]

    let placeholders = template ? templatePlaceholders : []; // nonTemplatePlaceholders;
    // let placeholders = nonTemplatePlaceholders;

    let months = ["July", "August", "September", "October", "November", "December", "Janurary", "Feburary", "March", "April", "May", "June"];
    // let months = ["Janurary", "Feburary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let monthWeeks = this.generateMonths(yearByMonthArr);
    let start = 0;
    let dayIndex = 0, monthIndex = 0;

    this.state = Object.keys(originalLayout).length != 0 ? originalLayout : {
      // this.state = { 
      monthItems: months.map(function (i, index, list) {
        let currEl = {
          i: i,
          x: start, // monthIndex++, //i * 2,
          y: 0,
          w: monthWeeks[index],
          h: 1, //1/2,// 1/8,
          static: true,
          isResizable: false,
          add: i === (list.length),
          bg: "white", // "#" + ((1 << 24) * Math.random() | 0).toString(16), //this.randomColor(),
          key: (Math.random() * 100000).toFixed(0) + monthIndex,
        }
        start += monthWeeks[index];
        return currEl;
      }),
      dateItems: yearByWeekArr.map(function (i, index, list) {
        return {
          i: new Date(i).getDate(),
          x: dayIndex++, //i * 2,
          y: 1,// 1/8,
          w: 1, // min should be 2 or 3 weeks? 
          h: 1, //1/2, //1/8,
          static: true,
          isResizable: false,
          add: i === (list.length),
          bg: "white", // "#" + ((1 << 24) * Math.random() | 0).toString(16), //this.randomColor(),
          key: (Math.random() * 100000).toFixed(0) + dayIndex,
        }
      }),
      items: placeholders,
      newCounter: 0,
      newItemTitle: "",
      newItemLength: 1,
      newItemColor: "#DDD9C4", //"#FFFFFF", //"#000000", //"#1C1CF2",
      year: year,
      date: startDate,
      textNotes: "",
      isTemplate: template,
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handlePremadeButton = this.handlePremadeButton.bind(this);
    // this.onAddItem = this.onAddItem.bind(this);
    this.onResizeStop = this.onResizeStop.bind(this);
    // this.generateYearTimeline = this.generateYearTimeline.bind(this);


    console.log(this.state)
  }


  findFirstMondayInJuly(year) {
    let mondayDate;
    let count = 0;
    for (let i = 0; i <= 30; i++) {
      let currDate = new Date(year, 6, i);

      if (currDate.getDay() == 1) {
        // if(count == 2){
        // console.log("HERERERE IN THE FIRST MONDAY FINDER");
        // console.log(currDate);
        // currDate.setDate(currDate.getDate() - 7);
        return currDate;
        // }
        count++;
      }
    }
  }

  generateYearTimeline(year) {
    // year = 2021;
    console.log("Start Date in Timeline: " + year);// JSON.stringify(date));
    // console.log("Start Date " + date.day + " " + date.month + " " + date.year);
    let dates = [];
    let numWeeks = 1;
    let numDays = 7;
    const weeksInYear = 52;
    // let startDate = new Date(2022, 5, 27);  // Date(Year, Month, Day)
    // let startDate = new Date(date, 5, 27);  // Date(Year, Month, Day)
    let startDate = this.findFirstMondayInJuly(year);  // Date(Year, Month, Day)
    dates.push(startDate.setDate(startDate.getDate() + 0));
    // dates.concat(startDate.toDateString);

    // console.log(startDate);

    for (let i = 0; i <= weeksInYear; i++) {
      // console.log(startDate);

      let currDate = startDate.setDate(startDate.getDate() + (numWeeks * numDays));
      dates.push(currDate);
      // dates.concat(startDate.toDateString);
    }
    // console.log(dates);
    return dates;

  }

  generateMonths(yearArr) {
    // console.log(yearArr);
    let monthLengths = [];
    let currLength = 0;
    let oldMonth = new Date(yearArr[0]).getMonth();
    yearArr.map(el => {
      // console.log("Months Test Method " + new Date(el).getMonth());
      let currMonth = new Date(el).getMonth();
      if (currMonth == oldMonth) {
        // console.log("CurrMonth: " + currMonth + " OldMonth: " + oldMonth);
        currLength++
      } else {
        if (currMonth == 6 && currLength > 4) {
          currLength = 4;
        }
        monthLengths.push(currLength);
        // monthLengths[currMonth] = currLength
        currLength = 1;
      }
      oldMonth = currMonth;
    })
    return monthLengths;
  }

  createElement(el, isRemove) {
    const removeStyle = {
      position: "absolute",
      right: "1px",
      // left: "5px",
      top: -8,
      // bottom: 10,
      cursor: "pointer",
      // display: "hidden",
    };

    const i = el.i;
    let blockWidth = el.w;
    let labelWidth = i.length;
    let rotateText = labelWidth > 4 && blockWidth <= 2

    return (
      <div className="flex items-center justify-center cursor-grab active:cursor-grabbing " key={el.key} data-grid={el} style={{ backgroundColor: el.bg }}>

        {rotateText == true ?
          <span className="text-sm transform -rotate-90">{i}</span> :
          <span className="text-sm">{i}</span>
        }

        {isRemove && <span
          // className="remove text-gray-500 font-medium w-2 h-2"
          className="remove text-gray-500 font-medium opacity-0 hover:opacity-100 w-2 h-5"
          style={removeStyle}
          onClick={this.onRemoveItem.bind(this, el.key)}// i)}
        >
          x
        </span>}
      </div>
    );
  }

  onRemoveItem(key) {
    // console.log("removing", key);
    this.setState({ items: _.reject(this.state.items, { key: key }) });
  }

  onDrop = (layout, layoutItem, _event) => {
    // alert(`Dropped element props:\n${JSON.stringify(layoutItem, ['i', 'x', 'y', 'w', 'h'], 2)}`);
    // console.log(layoutItem);
    // console.log(_event);
    // console.log(layout);
    let newItem = {
      i: "n" + this.state.newCounter,
      x: layoutItem.x,
      y: layoutItem.y, // > 0 ? layoutItem.y : layoutItem.y + 1,
      w: layoutItem.w,
      h: layoutItem.h,
      minW: 1,
      // maxW: 10, 
      minH: 1 / 2 - (1 / 100),
      // maxH: 2,
      bg: "#" + ((1 << 24) * Math.random() | 0).toString(16), //this.randomColor() 
      key: (Math.random() * 100000).toFixed(0)

    }
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat(newItem),
      // Increment the counter to ensure key is always unique.
      newCounter: this.state.newCounter + 1
    })
  };

  onResizeStop = (layout, oldItem, newItem, placeholder, e, element) => {
    // alert(`Resized Props:, \n${JSON.stringify(layoutItem, ['i', 'x', 'y', 'w', 'h'], 2)}`);
    console.log("Im Resized!!");
    alert("IM HERERERE");
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(values => ({ ...values, [name]: value }));
  }

  handleSubmit(event) {
    // alert('Title Submitted: ' + this.state.newItemTitle + '\nWeeks Submitted: ' + this.state.newItemLength );
    event.preventDefault();
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat(
        {
          i: this.state.newItemTitle, //"n" + this.state.newCounter,
          x: (this.state.items.length * 2) % (this.state.cols || 12),
          // x: this.state.items.length + 1,
          y: 4,// Infinity, // puts it at the bottom
          w: parseInt(this.state.newItemLength),
          h: 1,
          minW: 1,
          // maxW: 10, 
          minH: 1 / 2 - (1 / 100),
          // maxH: 2,
          bg: this.state.newItemColor, // "#" + ((1 << 24) * Math.random() | 0).toString(16), //this.randomColor()
          key: (Math.random() * 100000).toFixed(0) + (this.state.items.length * 2) % (this.state.cols || 12),
        }),
      // Increment the counter to ensure key is always unique.
      newCounter: this.state.newCounter + 1
    }, localStorage.setItem(this.state.year, JSON.stringify(this.state)));
  }

  handlePremadeButton(event) {

    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat(
        {
          i: name, //"n" + this.state.newCounter,
          x: (this.state.items.length * 2) % (this.state.cols || 12),
          // x: this.state.items.length + 1,
          y: 4,// Infinity, // puts it at the bottom
          w: parseFloat(value),
          h: 1,
          minW: 1,
          // maxW: 10, 
          minH: 1 / 2 - (1 / 100),
          // maxH: 2,
          bg: ItemColors[name], // this.state.newItemColor, // "#" + ((1 << 24) * Math.random() | 0).toString(16), //this.randomColor()
          key: (Math.random() * 100000).toFixed(0) + (this.state.items.length * 2) % (this.state.cols || 12),
          // key: `${name}_${(Math.random() * 100000).toFixed(0) + (this.state.items.length * 2) % (this.state.cols || 12)}`,
        }),
      // Increment the counter to ensure key is always unique.
      newCounter: this.state.newCounter + 1
    }, localStorage.setItem(this.state.year, JSON.stringify(this.state)));

    // localStorage.setItem(this.state.year, JSON.stringify(this.state));
    
  }

  handleMessageChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    // this.setState(values => ({ ...values, [name]: value }));

    this.setState({ textNotes: value }, localStorage.setItem(this.state.year, JSON.stringify(this.state)));
    // localStorage.setItem(this.state.year, JSON.stringify(this.state));
  };




  resetLayout() {
    this.setState({ layouts: {} });
  }

  onLayoutChange = (layout, layouts) => {
    // console.log(layout);
    // this.setState({ layout: layout });
    this.setState({ layouts }, () => localStorage.setItem(this.state.year, JSON.stringify(this.state)));
    // localStorage.setItem(this.state.year, JSON.stringify(this.state));
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }

  // resetLayout() {
  //   this.setState({ layout: {} });
  // }


  render() {

    return (
      this.state.year &&
      <div className="">
        <form onSubmit={this.handleSubmit} className="print:hidden">

          <div className="flex justify-center items-center ">
            <div className="text-left pl-5">
              <label>Name/Title:
                <input
                  className="border border-blue-500 rounded-md text-center"
                  type="text"
                  name="newItemTitle"
                  value={this.state.newItemTitle || ""}
                  onChange={this.handleChange}
                />
              </label>
            </div>

            <div className=" text-left pl-5 ">
              <label>Length (in weeks):
                <input
                  className="border border-blue-500 rounded-md justify-end text-center w-14"
                  type="number"
                  name="newItemLength"
                  min={1}
                  max={52}
                  value={this.state.newItemLength || ""}
                  onChange={this.handleChange}
                />
              </label>
            </div>

            <div className=" text-left pl-5 ">
              <label>Color:</label>
              <input type="color" id="newItemColor" name="newItemColor" value={this.state.newItemColor} onChange={this.handleChange} />
              {/* <SketchPicker className=""></SketchPicker> */}

              {/* <select
                className="border border-blue-500 rounded-md justify-end text-center"
                id="Color"
                name="newItemColor"
                onChange={this.handleChange}
                style={{ backgroundColor: this.state.newItemColor }}>
                {
                  Object.keys(ItemColors).map((key, index) => {
                    // console.log(ItemColors[key]);
                    return (
                      <option value={ItemColors[key]} key={key}>{key}</option>
                    );
                  })
                }
              </select> */}

              {/* <select
                  className="border border-blue-500 rounded-md justify-end text-center"
                  id="Color"
                  name="newItemColor"
                  onChange={this.handleChange}
                  style={{ backgroundColor: this.state.newItemColor }}>
                  <option value="white"> White  </option>
                  <option value="#E6B8B7">Red</option>
                  <option value="orange">Orange</option>
                  <option value="blue">Blue</option>
                  <option value="magenta">Magenta</option>
                </select> */}
            </div>

            {/* <div className=" text-left pl-5 ">
              <label>Defaults:</label>
              <select
                className="border border-blue-500 rounded-md justify-end text-center"
                id="Color"
                name="newItemColor"
                onChange={this.handleChange}
                style={{ backgroundColor: this.state.newItemColor }}>
                <option value={{}}>Surgery</option>
                <option value="#E6B8B7">Medicine</option>
                <option value="orange">Orange</option>
                <option value="blue">Blue</option>
                <option value="magenta">Magenta</option>
              </select>
            </div> */}

            <input className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 my-2 rounded justify-center ml-5" type="submit" value="Add Item" />

          </div>
        </form>


        {/* <button onClick={() => this.resetLayout()}>Reset Layout</button> */}



        <button className="bg-[#DDD9C4] hover:bg-[#DCD19E] px-1 rounded my-1 mx-1 border border-black print:hidden" onClick={this.handlePremadeButton} name='Surgery' value={8}>Surgery</button>
        <button className="bg-[#E6B8B7] hover:bg-[#DF8686] px-1 rounded my-1 mx-1 border border-black print:hidden" onClick={this.handlePremadeButton} name="Medicine" value={8}>Medicine</button>
        <button className="bg-[#8DB4E2] hover:bg-[#6EA6E2] px-1 rounded my-1 mx-1 border border-black print:hidden" onClick={this.handlePremadeButton} name="Psychiatry" value={4}>Psychiatry</button>
        <button className="bg-[#FCD5B4] hover:bg-[#F5B480] px-1 rounded my-1 mx-1 border border-black print:hidden" onClick={this.handlePremadeButton} name="Pediatrics" value={6}>Pediatrics</button>
        <button className="bg-[#D9D9D9] hover:bg-[#BABABA] px-1 rounded my-1 mx-1 border border-black print:hidden" onClick={this.handlePremadeButton} name="OBGYN" value={6}>OBGYN</button>
        <button className="bg-[#B8CCE4] hover:bg-[#83A3CE] px-1 rounded my-1 mx-1 border border-black print:hidden" onClick={this.handlePremadeButton} name="Fam Med" value={3}>Family Med</button>
        <button className="bg-[#CCC0DA] hover:bg-[#B799DB] px-1 rounded my-1 mx-1 border border-black print:hidden" onClick={this.handlePremadeButton} name="Neurology" value={3}>Neurology</button>
        <button className="bg-[#93CDDC] hover:bg-[#5FC4DD] px-1 rounded my-1 mx-1 border border-black print:hidden" onClick={this.handlePremadeButton} name="EM" value={4}>Emergency Med</button>
        <button className="bg-[#D8E4BC] hover:bg-[#C4E184] px-1 rounded my-1 mx-1 border border-black print:hidden" onClick={this.handlePremadeButton} name="Electives" value={3}>Electives</button>
        <button className="bg-[#EBF1DE] hover:bg-[#E1F4B8] px-1 rounded my-1 mx-1 border border-black print:hidden" onClick={this.handlePremadeButton} name="Research" value={4}>Research</button>
        <button className="bg-[#D1FAE5] hover:bg-[#A7F3D0] px-1 rounded my-1 mx-1 border border-black print:hidden" onClick={this.handlePremadeButton} name="Vaca" value={4}>Vacation</button>


        <ResponsiveReactGridLayout
          {...this.props}
          onLayoutChange={this.onLayoutChange}
          onBreakpointChange={this.onBreakpointChange}
          onDrop={this.onDrop}
          // WidthProvider option
          measureBeforeMount={false}
          // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
          // and set `measureBeforeMount={true}`.
          useCSSTransforms={this.state.mounted}
          // compactType={this.state.compactType}
          compactType={null}
          // preventCollision={!this.state.compactType}
          // isDroppable={true}
          layouts={this.state.layouts}
          style={{ minHeight: "150px" }}
        >
          {_.map(this.state.dateItems, el => this.createElement(el, false))}
          {_.map(this.state.monthItems, el => this.createElement(el, false))}
          {_.map(this.state.items, el => this.createElement(el, true))}

        </ResponsiveReactGridLayout>


        {
          !this.state.isTemplate && <div className="flex justify-center print:hidden">
            <div className="mb-3 w-3/4">
              {/* <label className="form-label inline-block mb-2 text-gray-700"> Notes </label> */}
              <textarea
                name={`textBox_` + this.state.year}
                onChange={this.handleMessageChange}
                className="form-control block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded mt-4
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none "
                // id="exampleFormControlTextarea1"
                rows="3"
                placeholder="Notes here"
                value={this.state.textNotes}
              >
              </textarea>
            </div>
          </div>
        }
      </div>
    );
  }
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      // ls = JSON.parse(global.localStorage.getItem("rgl-9")) || {};
      ls = JSON.parse(global.localStorage.getItem(this.state.year)) || {};
      console.log("GET LOCAL STORAGE RESULT:");
      console.log(ls);
    } catch (e) {
      /*Ignore*/
    }
  }
  // return ls[key];
  return ls;
}

function saveToLS(key, value) {
  // console.log("IM here in storage");
  // console.log(this.state);
  if (global.localStorage) {
    global.localStorage.setItem(
      // "rgl-9", 
      this.state.year,
      JSON.stringify(
        // { [key]: value }
        this.state
      )
    );
  }
}

// if (process.env.STATIC_EXAMPLES === true) {
import("./test-hook.jsx").then(fn => fn.default(MyGrid));
// }