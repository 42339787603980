import React from "react";
import GridLayout from "react-grid-layout";
import './grid2.css';

export default class MyGrid2 extends React.Component {
  render() {
    // layout is an array of objects, see the demo for more complete usage
    const layout = [
      { i: "Required", x: 0, y: 0, w: 1, h: 2, static: true },
      // { i: "Pulm", x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 },
      { i: "Pulm", x: 1, y: 0, w: 5, h: 2 },
      { i: "c", x: 4, y: 0, w: 5, h: 2 }
    ];
    return (
      <GridLayout
        className="layout"
        layout={layout}
        cols={12}
        rowHeight={30}
        width={1200}
      >
        {/* <div key="Required" style={{'backgroundColor': 'red'}}>a</div>
        <div key="c">c</div> */}

          {layout.map( item => {
            return <div key={`${item.i}`} id={`${item.i}`} style={{backgroundColor:'red'}}>{item.i}</div>
          })}

      </GridLayout>
    );
  }
}



// import _ from "lodash";
// import RGL, { WidthProvider } from "react-grid-layout";

// const ReactGridLayout = WidthProvider(RGL);

// export default class MyGrid2 extends React.PureComponent {
//   static defaultProps = {
//     className: "layout",
//     items: 20,
//     rowHeight: 30,
//     onLayoutChange: function() {},
//     cols: 12
//   };

//   constructor(props) {
//     super(props);

//     const layout = this.generateLayout();
//     this.state = { layout };
//   }

//   generateDOM() {
//     return _.map(_.range(this.props.items), function(i) {
//       return (
//         <div key={i}>
//           <span className="text">{i}</span>
//         </div>
//       );
//     });
//   }

//   generateLayout() {
//     const p = this.props;
//     return _.map(new Array(p.items), function(item, i) {
//       const y = _.result(p, "y") || Math.ceil(Math.random() * 4) + 1;
//       return {
//         x: (i * 2) % 12,
//         y: Math.floor(i / 6) * y,
//         w: 2,
//         h: y,
//         i: i.toString()
//       };
//     });
//   }

//   onLayoutChange(layout) {
//     this.props.onLayoutChange(layout);
//   }

//   render() {
//     return (
//       <ReactGridLayout
//         layout={this.state.layout}
//         onLayoutChange={this.onLayoutChange}
//         {...this.props}
//       >
//         {this.generateDOM()}
//       </ReactGridLayout>
//     );
//   }
// }