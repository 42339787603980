import React from "react"
import MyGrid from "./grid"
import clerkPhoto from '../clerkshipScreenshot.png'


export default function AccordionYears() {

    return (
        // <div class='flex items-center justify-center min-h-screen from-purple-100 via-red-300 to-indigo-500 bg-gradient-to-br'>
        <div className=' w-full px-10 py-8 mx-auto bg-white rounded-none shadow-xl print:hidden'>


<details className=" bg-mayo-blue open:text-black open:bg-gray-300 open:shadow-lg p-6 mb-3 rounded-lg text-white cursor-pointer open:" open>
                <summary className="text-md leading-6 font-semibold select-none text-start">
                    Template/Example
                </summary>
                <MyGrid year={2021} startDate={{ day: 27, month: 5, year: 2022 }} template={true}></MyGrid>
                <div className="mt-3 text-md leading-6 text-slate-600 dark:text-slate-400">
                </div>
            </details>


            {/* <MyGrid year={2021} startDate={{ day: 27, month: 5, year: 2022 }} template={true}></MyGrid> */}

            <details className=" bg-mayo-blue open:text-black open:bg-gray-300 open:shadow-lg p-6 my-3 rounded-lg text-white cursor-pointer open:" open>
                <summary className="text-md leading-6 font-semibold select-none text-start">
                    Clerkship Screenshot
                </summary>
                <div className="mt-3 text-md leading-6 text-slate-600 dark:text-slate-400">
                    <div className="">
                        <img src={clerkPhoto} className=" active:scale-125 cursor-zoom-in " alt="clerkshipPhoto" />
                    </div>
                </div>
            </details>
            <details className=" bg-mayo-blue open:text-black open:bg-gray-300 open:shadow-lg p-6 mb-3 rounded-lg text-white cursor-pointer open:" open>
                <summary className="text-md leading-6 font-semibold select-none text-start">
                    {`Year 2 (2022-2023)`}
                </summary>
                <MyGrid year={2022} startDate={{ day: 27, month: 5, year: 2022 }} template={false}></MyGrid>
                <div className="mt-3 text-md leading-6 text-slate-600 dark:text-slate-400">
                </div>
            </details>
            <details className="w-full bg-mayo-blue open:text-black open:bg-gray-300 open:shadow-lg p-6 mb-3 rounded-lg text-white cursor-pointer open:" >
                <summary className="text-md leading-6 font-semibold select-none text-start">
                    {`Year 3 (2023-2024)`}
                </summary>
                <MyGrid year={2023} startDate={{ day: 27, month: 5, year: 2022 }} template={false}></MyGrid>
                <div className="mt-3 text-md leading-6 text-slate-600 dark:text-slate-400">
                </div>
            </details>
            <details className="w-full bg-mayo-blue open:text-black open:bg-gray-300 open:shadow-lg p-6 mb-3 rounded-lg text-white cursor-pointer open:">
                <summary className="text-md leading-6 font-semibold select-none text-start">
                    {`Year 4 (2024-2025)`}
                </summary>
                <MyGrid year={2024} startDate={{ day: 27, month: 5, year: 2022 }} template={false}></MyGrid>
                <div className="mt-3 text-md leading-6 text-slate-600 dark:text-slate-400">
                    {/* <p>The mug is round. The jar is round. They should call it Roundtine.</p> */}
                </div>
            </details>

            <button className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-2 rounded" onClick={ async() =>{
                await window.location.reload(true);
            }}> Refresh Page </button>
            <button className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={ async() =>{
                // await window.location.reload(true);
                await window.print();
            }}>
                Print
            </button>
            <p className="text-gray-400">⚠️Printing Experimental/Temperamental⚠️ Please refresh page before attempting to print </p>
        </div>
        // </div>
    )
}