import logo from './logo.svg';
import clerkPhoto from './clerkshipScreenshot.png'

import './App.css';
import MyGrid from './components/grid';
import MyGrid2 from './components/grid2';
import DragItem from './components/sidePanel/DragItem';
import NewItemForm from './components/sidePanel/NewItemForm';
import SidePanel from './components/sidePanel/SidePanel';
import Accordion from './components/Accordion';
import AccordionYears from './components/Accordion';


// import 'react-grid-layout/css/styles.css'
// import 'react-resizable/css/styles.css'


function App() {
  let startYear = { day: 27, month: 5, year: 2022 };

  return (
    <div className="App bg-mayo-blue">

        {/* <div className='w-80 h-full'>
          <SidePanel></SidePanel>
        </div> */}
        {/* <div className='ml-80'> */}
        
        <div className=' mx-28 '>

          {/* <div className=' flex items-center justify-center w-full h-24 bg-gray-800 '> */}
          <div className=' h-24 justify-center w-full bg-black '>
            <h1 className=" pt-5 text-3xl text-white font-extrabold ">Rotation Experimentation</h1>
            <h6 className='text-gray-500 '>~ by Made-A-Line Products ~</h6>
            {/* <h3 className=' text-gray-500'> - time away from loved ones - </h3> */}
          </div>


          {/* <MyGrid year={2021} startDate ={startYear}></MyGrid> */}
          <AccordionYears></AccordionYears >

          {/* <div className="hidden print:block">
            <h1 className='text-white text-3xl'>Year2 ({2022})</h1>
            <MyGrid year={2022} startDate={startYear}></MyGrid>
            <h1 className='text-white text-3xl'>Year3 ({2023})</h1>
            <MyGrid year={2023} startDate={startYear}></MyGrid>
            <h1 className='text-white text-3xl'>Year4 ({2024})</h1>
            <MyGrid year={2024} startDate={startYear}></MyGrid>
          </div> */}


        </div>

      <footer className='text-gray-400 p-4'>Designed by: M.W.</footer>

          {/* This section is for printing purposes */}
      <div className="hidden print:block">
            <h1 className='text-white text-3xl'>Year2 (2022-2023)</h1>
            <MyGrid year={2022} startDate={startYear} template={false}></MyGrid>
            <h1 className='text-white text-3xl'>Year3 (2023-2024)</h1>
            <MyGrid year={2023} startDate={startYear} template={false}></MyGrid>
            <h1 className='text-white text-3xl'>Year4 (2024-2025)</h1>
            <MyGrid year={2024} startDate={startYear} template={false}></MyGrid>
          </div>
    </div>

  );
}

export default App;
